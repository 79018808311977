import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What exactly?"
    gridify={true}
    options={[
      {
        label: "I need crazy animations",
        link: "/app/tool/principle/"
      },
      {
        label: "I need advanced no-code interactions",
        link: "/app/tool/protopie/"
      },
      {
        label: "I need component states management",
        link: "/app/tool/figma/"
      },
      {
        label: "I need to write code for creating interactions",
        link: "/app/tool/framer/"
      }
  ]}/>
)

export default Picker;


